<template>
  <div class="fill-height">
    <div class="pa-4" v-if="renderCategories">
      <div class="my-3 text-center" v-show="renderSpotlight">
        <h4 class="mb-1 mt-3 align-center justify-center text-center">
          VEJA MEUS DESTAQUES
        </h4>
        <div v-if="spotlightProducts.length == 0">
          <span class="d-flex justify-center text-center fill-height" style="margin-bottom: 20%">
            Ops! Ainda estamos adicionando. Aguarde.
          </span>
        </div>
        <ProductList :callBackSpotlight="setSpotlightProducts" :scrollingLeft="true" />
      </div>
      <div v-show="renderCategories">
        <div class="text-center py-2 font-weight-bold">
          Quer ver alguma categoria específica?
        </div>
        <HorizontalCategoriesList :callBackCategory="setCategory" :callBackCategories="setCategories"
          style="margin-bottom: 5%;" />
      </div>
      <ProductList :callBackProduct="setProducts" :categorySelected="categorySelected" :scrollingLeft="false" />
      <div v-if="renderProducts == false">
        <span style="margin-top:20%" :style="!$isMobile ? 'min-height: 600px' : 'min-height: 250px'"
          class="d-flex justify-center fill-height text-center">
          Ops! Ainda estamos adicionando. Aguarde.
        </span>
      </div>
    </div>
    <div v-else class="d-flex align-center fill-height justify-center" style="min-height: 600px">
      <h4>Ops! Ainda estamos adicionando. Aguarde.</h4>
    </div>
  </div>
</template>

<script>
import ProductList from "../components/ProductList.vue";
import HorizontalCategoriesList from "../components/HorizontalCategoriesList.vue";
export default {
  name: "Products",
  components: {
    ProductList,
    HorizontalCategoriesList,
  },
  data: () => ({
    loadingCategories: false,
    loadingName: false,
    tryAgain: false,

    renderSpotlight: true,
    renderProducts: true,
    renderCategories: true,

    dialog: false,
    name: "Temp",

    categorySelected: null,
    Pcategories: [],
    spotlightProducts: [],
    products: [],
  }),
  methods: {
    setSpotlightProducts(products) {
      if (products.length > 0) {
        this.spotlightProducts = products;
        this.renderSpotlight = true;
      } else {
        this.renderSpotlight = false;
      }
    },

    setProducts(products) {
      if (products.length > 0) {
        this.products = products;
        this.renderProducts = true;
      } else {
        this.renderProducts = false;
      }
    },

    setCategory(category) {
      this.categorySelected = category;
    },
    setCategories(categories) {
      if (categories.length > 0) {
        this.Pcategories = categories;
        this.renderCategories = true;
      } else {
        this.renderCategories = false;
      }
    },

    getBusinessName() {
      this.loadingName = true;
      this.tryAgain = false;

      this.$axios
        .get("/web/business-name/" + this.$route.params.businessId)
        .then((response) => {
          this.name = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.tryAgain = true;
        })
        .finally(() => {
          this.loadingName = false;
        });
    },
  },
  mounted() {
    this.getBusinessName();
  },

  watch: {},
};
</script>

